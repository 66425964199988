import { MetaTagArray } from "@/components/MetaTagAll/MetaDataArray";
import MetaTagSSR from "@/components/MetaTagAll/MetaTagSSR";
import { useEffect, useState } from "react";
// import EnterPrise from "@/components/SubHome/EnterPrise";
import SkeletonEnterPrise from "@/components/SubHome/SkeletonEnterPrise";
// import { fetchIPDetails } from "@/utils/ApiServices";
// import { getLocalStorageItem, setLocalStorageItem } from "@/utils/storageUtils";
import dynamic from "next/dynamic";
import Layout from "@/components/Layout/Layout";
import StartBuilding from "@/components/Home/StartBuilding";
const EnterPrise = dynamic(() => import("../components/SubHome/EnterPrise"), {
  loading: () => (<SkeletonEnterPrise />
  ),
  ssr: false,
});
// const VideoAnimation = dynamic(
//   () => import("@/components/Common/VideoAnimation"),
//   { ssr: false }
// );
const ClutchLogoSection = dynamic(() =>
  import("@/components/Home/ClutchLogoSection")
);
// const ClutchReviewSection = dynamic(() =>
//   import("@/components/Home/ClutchReviewSection")
// );
const FeelWeArePerMatch = dynamic(() =>
  import("@/components/Home/FeelWeArePerMatch")
);
// const FormSection = dynamic(() => import("@/components/Home/FormSection"));
// const NewFaq = dynamic(() => import("@/components/Home/NewFaq"));
const CompanyLogo = dynamic(() => import("@/components/SubHome/CompanyLogo"));
const CoreServices = dynamic(() => import("@/components/SubHome/CoreServices"));
const CounterSection = dynamic(() =>
  import("@/components/SubHome/CounterSection")
);
const ExpertMind = dynamic(() => import("@/components/SubHome/ExpertMind"));
const GlobalQuotes = dynamic(() => import("../components/Common/GlobalQuotes"));
// const LandingServices = dynamic(() =>
//   import("../components/Home/LandingServices")
// );
const LearnSkills = dynamic(() => import("../components/Home/LearnSkills"));
const Portfolio = dynamic(() => import("../components/Home/Portfolio"));
const TechnologiesLogoHome = dynamic(() => import("../components/Home/TechnologiesLogoHome"));
const SquaresDesign = dynamic(() => import("../components/Home/SquaresDesign"));
const ProjectTeam = dynamic(() => import("../components/Home/ProjectTeam"));
const JobVideoSection = dynamic(() => import("../components/Home/JobVideoSection"));
// const PortfolioCards = dynamic(() =>
//   import("../components/Home/PortfolioCards")
// );

export default function Home({ metaData }) {
  // const [ipDetails, setIpDetails] = useState(null);
  // useEffect(() => {
  //   const extIpDetails = getLocalStorageItem("ipDetails");
  //   const getIpDetail = async () => {
  //     try {
  //       const response = await fetch("https://api64.ipify.org?format=json");
  //       const data = await response.json();
  //       const ip = data?.ip;

  //       if (ip) {
  //         const ipData = await fetchIPDetails(ip);
  //         setLocalStorageItem("ipDetails", ipData.data);
  //         setIpDetails(ipData.data);
  //       }
  //     } catch (err) {
  //       console.error("Error fetching IP details:", err);
  //     }
  //   };
  //   if (!extIpDetails) {
  //     getIpDetail();
  //   }
  // }, []);

  return (
    <Layout>
      <MetaTagSSR metaData={metaData} />
      <EnterPrise />
      <CompanyLogo />
      <ExpertMind />
      <CounterSection />
      <div>
        <GlobalQuotes />
      </div>
      {/* <VideoAnimation /> */}
      <CoreServices />
      <TechnologiesLogoHome />
      <SquaresDesign />
      <Portfolio />
      {/* <PortfolioCards /> */}
      {/* <LandingServices /> */}
      {/* <ClutchReviewSection /> */}
      <ClutchLogoSection />
      <ProjectTeam />
      <JobVideoSection />
      <LearnSkills />
      <FeelWeArePerMatch />
      <StartBuilding />
      {/* <NewFaq /> */}
      {/* <FormSection ipDetails={ipDetails} /> */}
    </Layout>
  );
}

// ✅ `getStaticProps` for metadata (No IP fetching here)
export async function getStaticProps() {
  const metaData = MetaTagArray?.find((d) => d.pageName === "/") || null;

  return {
    props: {
      metaData,
    },
    revalidate: 60, // ISR: Page updates every 60 seconds
  };
}
